import React from "react";
import ReactDOM from "react-dom";
import Minter from "./components/minter";
import "./styles/styles.css";

ReactDOM.render(
	<React.StrictMode>
		<Minter />
	</React.StrictMode>,
	document.getElementById("root")
);
