import { useEffect, useState } from "react";
import Web3 from "web3";
import contract from "../contracts/abi.live.json";
import EthImg from "../assets/eth.webp";
import Arrow from "../assets/arrow.png";
/*eslint eqeqeq: "off"*/
/*eslint no-unused-vars: "off"*/

// console.log( "RELOADED ------------------------------------------------------" );
// console.log("OBJECT: "+JSON.stringify(mintInfo))

const initialInfoState = {
	connected: false,
	status: null,
	account: null,
	web3: null,
	contract: null,
	address: null,
	contractJSON: null,
};

const initialMintState = {
	loading: false,
	status: `Mint your ${contract.name} token`,
	contract_name: `${contract.name}`,
	amount: 1,
	supply: "0",
	cost: "0",
};

function Minter() {

	const [info, setInfo] = useState(initialInfoState);
	const [mintInfo, setMintInfo] = useState(initialMintState);

	const init = async (_request, _contractJSON) => {
		if (window.ethereum && window.ethereum.isMetaMask) {
			try {
				const accounts = await window.ethereum.request({
					method: _request,
				});
				const networkId = await window.ethereum.request({
					method: "net_version",
				});
				if (networkId == _contractJSON.chain_id) {
					let web3 = new Web3(window.ethereum);
					var web3Contract = new web3.eth.Contract(
						_contractJSON.abi,
						_contractJSON.address
					);
					setInfo((prevState) => ({
						...prevState,
						connected: true,
						status: null,
						account: accounts[0],
						web3: web3,
						contract: web3Contract,
						contractJSON: _contractJSON,
					}));
				} else {
					setInfo(() => ({
						...initialInfoState,
						status: `Change network to ${_contractJSON.chain}.`,
					}));
				}
			} catch (err) {
				console.log(err.message);
				setInfo(() => ({
					...initialInfoState,
				}));
			}
		} else {
			setInfo(() => ({
				...initialInfoState,
				status: "Please install metamask.",
			}));
		}
	};

	const initListeners = () => {
		if (window.ethereum) {
			window.ethereum.on("accountsChanged", () => {
				// window.location.reload();
			});
			window.ethereum.on("chainChanged", () => {
				window.location.reload();
			});

			//DC Added this
			// window.ethereum.on('disconnect', () => {
			// 	window.location.reload();
			// });
		}
	};

	const getSupply = async () => {
		const params = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.totalSupply().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params, "latest"],
			});
			setMintInfo((prevState) => ({
				...prevState,
				supply: info.web3.utils.hexToNumberString(result),
			}));
		} catch (err) {
			// console.log("OBJECT: "+JSON.stringify(err))
			setMintInfo((prevState) => ({
				...prevState,
				supply: 0,
			}));
		}
	};

	const getIsPaused = async () => {
		const params = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.paused().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params, "latest"],
			});
			setMintInfo((prevState) => ({
				...prevState,
				is_paused: info.web3.utils.hexToNumberString(result),
			}));
		} catch (err) {
			setMintInfo((prevState) => ({
				...prevState,
				is_paused: 0,
			}));
		}
	};

	const getTokenSupply = async () => {
		const params = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.tokenSupply().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params, "latest"],
			});
			setMintInfo((prevState) => ({
				...prevState,
				total_supply: info.web3.utils.hexToNumberString(result),
			}));
		} catch (err) {
			setMintInfo((prevState) => ({
				...prevState,
				total_supply: 0,
			}));
		}
	};

	const getPresaleSupply = async () => {
		const params = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.presaleSupply().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params, "latest"],
			});
			setMintInfo((prevState) => ({
				...prevState,
				total_supply: info.web3.utils.hexToNumberString(result),
			}));
		} catch (err) {
			setMintInfo((prevState) => ({
				...prevState,
				total_supply: 0,
			}));
		}
	};

	const getTotalSupply = async () => {
		const params_presale = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.presaleActive().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params_presale, "latest"],
			});
			if (info.web3.utils.hexToNumberString(result) == '1') {
				getPresaleSupply();
			} else {
				getTokenSupply();
			}
		} catch (err) {
			getTokenSupply();
		}
	};

	const getPresaleCost = async () => {
		const params = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.presaleCost().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params, "latest"],
			});
			setMintInfo((prevState) => ({
				...prevState,
				cost: info.web3.utils.hexToNumberString(result),
			}));
		} catch (err) {
			setMintInfo((prevState) => ({
				...prevState,
				cost: "0",
			}));
		}
	};

	const getCost = async () => {
		const params = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.tokenCost().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params, "latest"],
			});
			setMintInfo((prevState) => ({
				...prevState,
				cost: info.web3.utils.hexToNumberString(result),
			}));
		} catch (err) {
			setMintInfo((prevState) => ({
				...prevState,
				cost: "0",
			}));
		}
	};

	const getPrice = async () => {
		const params_presale = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.presaleActive().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params_presale, "latest"],
			});
			if (info.web3.utils.hexToNumberString(result) == '1') {
				getPresaleCost();
			} else {
				getCost();
			}
		} catch (err) {
			getCost();
		}
	};

	const getTokenPerAddressLimit = async () => {
		const params = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.tokenPerAddressLimit().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params, "latest"],
			});
			setMintInfo((prevState) => ({
				...prevState,
				max_per_address: info.web3.utils.hexToNumberString(result),
			}));
		} catch (err) {
			setMintInfo((prevState) => ({
				...prevState,
				max_per_address: "5",
			}));
		}
	};

	const getTokenMaxMintAmount = async () => {
		const params = {
			to: info.contractJSON.address,
			from: info.account,
			data: info.contract.methods.tokenMaxMintAmount().encodeABI(),
		};
		try {
			const result = await window.ethereum.request({
				method: "eth_call",
				params: [params, "latest"],
			});
			setMintInfo((prevState) => ({
				...prevState,
				max_mint_amount: info.web3.utils.hexToNumberString(result),
			}));
		} catch (err) {
			setMintInfo((prevState) => ({
				...prevState,
				max_mint_amount: "5",
			}));
		}
	};

	const mint = async () => {
		const params = {
			to: info.contractJSON.address,
			from: info.account,
			value: String(
				info.web3.utils.toHex(Number(mintInfo.cost) * mintInfo.amount)
			),
			data: info.contract.methods
				.mint(info.account, mintInfo.amount)
				.encodeABI(),
		};
		try {
			setMintInfo((prevState) => ({
				...prevState,
				loading: true,
				status: `Minting ${mintInfo.amount}`,
			}));
			const txHash = await window.ethereum.request({
				method: "eth_sendTransaction",
				params: [params],
			});
			setMintInfo((prevState) => ({
				...prevState,
				loading: false,
				status:
					"Thankyou, your NFT will show up on Opensea, once the transaction is successful.",
			}));
			getSupply();
		} catch (err) {
			setMintInfo((prevState) => ({
				...prevState,
				loading: false,
				status: err.message,
			}));
		}
	};

	const updateAmount = (newAmount) => {
		console.log(newAmount)
		if (newAmount <= mintInfo.max_mint_amount && newAmount >= 1) {
			setMintInfo((prevState) => ({
				...prevState,
				amount: newAmount,
			}));
		}
	};

	const connectToContract = (_contractJSON) => {
		init("eth_requestAccounts", _contractJSON);
	};

	useEffect(() => {
		// connectToContract(contract);
		initListeners();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (info.connected) {
			getIsPaused();
			getTotalSupply();
			getSupply();
			getPrice();
			getTokenMaxMintAmount();
			getTokenPerAddressLimit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [info.connected]);

	// async function connect() {
	// 	const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
	// 	const account = accounts[0];
	// 	connectToContract(contract);
	// }
	// <button onClick={connect}>Connect Wallet</button>

	return (

			<div className="minter_container">

				<div className="minter_header_text">
					PRESALE
				</div>
				<div className="minter_header_subtext">
					Mint Your NFT
				</div>

				{mintInfo.is_paused == '1' ? (
					<div className="minter_body">
						<p className="notice_notice_text">
							Minting is not available right now. Just follow us on Twitter for details of the next drop!
						</p>
					</div>
				) : (

				<div>

				{mintInfo.supply < contract.total_supply ? (

						<div className="minter_body">

							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									display: !info.connected ? 'block' : 'none',
								}} >

								<div className="minter_block" >
									<button
										//disabled={!info.connected || mintInfo.cost == "0"}
										disabled={!info.connected}
										className="minter_amount_button minus"
										onClick={() => updateAmount(mintInfo.amount - 1)}
									>
										-
									</button>
									<div style={{ width: 8 }}></div>

									<div className="minter_mint_box" >
										<div className="minter_mint_text" >Mint</div>
										<div className="minter_mint_amount" >{mintInfo.amount}</div>
									</div>

									<div style={{ width: 8 }}></div>
									<button
										//disabled={!info.connected || mintInfo.cost == "0"}
										disabled={!info.connected}
										className="minter_amount_button plus"
										onClick={() => updateAmount(mintInfo.amount + 1)}
									>
										+
									</button>
								</div>

							</div>

						{info.connected ? (

							<div>

								<div className="minter_block" >
									<button
										disabled={!info.connected}
										className="minter_amount_button minus"
										onClick={() => updateAmount(mintInfo.amount - 1)}
									>
										-
									</button>
									<div style={{ width: 8 }}></div>

									<div className="minter_mint_box" >
										<div className="minter_mint_text" >Mint</div>
										<div className="minter_mint_amount" >{mintInfo.amount}</div>
									</div>

									<div style={{ width: 8 }}></div>
									<button
										disabled={!info.connected}
										className="minter_amount_button plus"
										onClick={() => updateAmount(mintInfo.amount + 1)}
									>
										+
									</button>
								</div>

								<div className="minter_block_info">
									<img className="minter_eth_logo" src={EthImg} />
									<span className="minter_info_bold">
										PRICE {info.web3?.utils.fromWei(mintInfo.cost, "ether") * mintInfo.amount}
									</span>
									<span>{contract.chain_symbol}</span>
									<span className="minter_info_spacer"> | </span>
									<span className="minter_info_bold">{mintInfo.supply}/{mintInfo.total_supply} SOLD</span>
								</div>

								<div className="minter_button"
										disabled={!info.connected || mintInfo.cost == "0"}
										onClick={() => mint()}
									>
										Mint NFT
								</div>

								<div className="mint_notice">{mintInfo.max_per_address} tokens per wallet limit</div>

							</div>

						) : null}

						{mintInfo.status ? (

							<div className="minter_notice_text">{mintInfo.status}</div>

						) : null}

						{info.status ? (

							<p
								className="minter_notice_text"
								style={{ color: "var(--minter-error)" }}
							>
								{info.status}
							</p>

						) : null}
					</div>

				) : (

					<div className="minter_body">
						<p>
							{mintInfo.supply}/{mintInfo.total_supply}
						</p>
						<p className="notice_notice_text">
							This mint is sold out! Follow us on Twitter <a className="notice_link" target="_blank" rel="noreferrer" href={'http://twitter.com/YoloMyGiraffe'}>@YoloMyGiraffe</a> to find out about the next drop.
						</p>
					</div>

				)}

				</div>
				)} {/* End Minting Paused */}

				<div className="minter_footer">

					<div className="minter_block" style={{display: !info.connected ? 'block' : 'none'}} >
						<div className="minter_button" onClick={() => connectToContract(contract)}>
							{info.account ? "Connected" : "Connect Wallet"}
						</div>
					</div>

					{!info.connected ? (
						<div className="footer-spacer"></div>
					) : null}

					<div className="footer-text">
							{info.account ? "" : "Connect Metamask"}
							{info.connected ? (
							<span className="minter_account_text">
								{String(info.account).substring(0, 6) +
									"..." +
									String(info.account).substring(38)}
							</span>
					) : null}

						<a className="footer_link" target="_blank" rel="noreferrer"
							href={'https://etherscan.io/address/' + contract.address}>
							Contract
						</a>
					</div>

					{/*
					<button
						className="minter_connect_button"
						style={{
							backgroundColor: info.connected
								? "var(--minter-success)"
								: "var(--minter-warning)",
						}}
						onClick={() => connectToContract(contract)}
					>
						{info.account ? "Connected" : "Connect Wallet"}
					</button>

					<a style={{
							position: "absolute",
							bottom: 55,
							left: -75,
							color: "rgba(255,255,255,0.4)",
						}}
						className="_90"
						target="_blank"
						rel="noreferrer"
						href={'https://etherscan.io/address/' + contract.address}
						>
						{contract.name}
					</a>
					*/}

				</div>

			</div> //card

	);
}

export default Minter;
